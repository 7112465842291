<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M5.7325 8.958V6.7185C5.7325 3.0093 8.7418 0 12.4509 0 16.1601 0 19.1694 3.0093 19.1694 6.7185V8.958H19.9159C21.5629 8.958 22.9019 10.297 22.9019 11.9439V20.9019C22.9019 22.5488 21.5629 23.8879 19.9159 23.8879H4.986C3.3344 23.8879 2 22.5488 2 20.9019V11.9439C2 10.297 3.3344 8.958 4.986 8.958H5.7325ZM8.7185 8.958H16.1834V6.7185C16.1834 4.6563 14.5131 2.986 12.4509 2.986 10.3887 2.986 8.7185 4.6563 8.7185 6.7185V8.958Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
};
</script>
